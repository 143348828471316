import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer" >
            <span>R2S Version v3.0.0 (ME Bank/BOQ Combined GC and Legacy Engage - ap-southeast-2) </span>
        </footer>
    )
}

export default Footer