import React, { useState, useEffect, Fragment } from 'react'
import {MultiSelect} from "react-multi-select-component"
import Select from "react-select"
import {Button, InputGroup, InputGroupAddon, InputGroupText, Input, Label} from 'reactstrap'
import './UserDetails.css'
import backendAPI from '../../../../services/backend'
import AlertModal from '../../../Modals/alertModal/AlertModal'
import WaitingModal from '../../../Modals/waitingModal/WaitingModal'
import constants from '../../../../constants';
import { pureCloudService } from "../../../../services/purecloud";

const UserDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(true)
    const [isDisabledFlag, setisDisabledFlag] = useState(false)
    const [isUpdateFlag, setisUpdateFlag] = useState(false)
    const [dropDownsDisable,setDropDownsDisable] = useState(false)
    const [alertHeader, setAlertHeader] = useState("Error")

    const [roleSelected, setRoleSelected] = useState({})
    const [userSelected, setUserSelected] = useState({})
    const [sourceSelected, setsourceSelected] = useState([])
    const [pcDivisionsSelected, setpcDivisionsSelected] = useState([])
    const [pcQueuesSelected, setpcQueuesSelected] = useState([])
    const [divisionsSelected, setDivisionsSelected] = useState([])
    const [queuesSelected, setQueuesSelected] = useState([])
    const [stateSelected, setStateSelected] = useState(false)

    const [rolesList, setRolesList] = useState([])
    const [divisionsList, setDivisionsList] = useState([])
    const [queuesList, setQueuesList] = useState([])

    const [pcDivisionsList, setPcDivisionsList] = useState([])
    const [pcQueuesList, setPcQueuesList] = useState([])

    const [userList, setUserList] = useState([])
    const [displayQueueList, setDisplayQueueList] = useState()


    useEffect(() => {


        

        // const removeAll = (arrayToModify) => {
        //     const deleteIndex = arrayToModify.findIndex(obj => obj.value === "*")
        //     if ((deleteIndex > -1) && (arrayToModify.length > 1))
        //         arrayToModify.splice(deleteIndex, 1)
        //     return arrayToModify
        // }

        const replaceAll = (arrayToReplace) => {
            if ((arrayToReplace.length === 1) && (arrayToReplace[0].value === "*")) {
                return true
            }
            return false

        }
        

        const getUserDetails = async () => {
            try {
            const getUserMeResponse = await backendAPI.getUsersMe(sessionStorage.getItem(constants.GC_TOKEN))
            console.log(JSON.stringify(getUserMeResponse));
            console.log(` List of Users for this api is ${JSON.stringify(getUserMeResponse.user.users)}`);
            let gcusersList = sessionStorage.getItem(constants.GC_USER_LIST);
            if(gcusersList == undefined){
                console.log(`trying to fetch user details from genesys cloud`);
                let userEmailList = await pureCloudService.fetchGCusers(sessionStorage.getItem(constants.GC_ENVIRONMENT),sessionStorage.getItem(constants.GC_TOKEN));
                gcusersList = userEmailList;
                sessionStorage.setItem(constants.GC_USER_LIST,JSON.stringify(gcusersList))
            }else{
                try {
                    gcusersList = JSON.parse(gcusersList);
                } catch (error) {
                    //do nothing
                }
                console.log(`gcusersList has valid values ${JSON.stringify(gcusersList)}`);
            }

            setUserList(gcusersList ? gcusersList.filter((x => x.value !== '*')) : [])

            setRolesList(getUserMeResponse.user.roles ? getUserMeResponse.user.roles.filter((x => x.value !== '*')) : [])
            setDivisionsList(getUserMeResponse.user.divisions ? getUserMeResponse.user.divisions.filter((x => x.value !== '*')) : [])
            setQueuesList(getUserMeResponse.user.queues ? getUserMeResponse.user.queues.filter((x => x.value !== '*')) : [])

            
            setPcDivisionsList(getUserMeResponse.user.pcdivisions ? getUserMeResponse.user.pcdivisions.filter((x => x.value !== '*')) : [])
            setPcQueuesList(getUserMeResponse.user.pcqueues ? getUserMeResponse.user.pcqueues.filter((x => x.value !== '*')) : [])

            console.log(`userList is ${JSON.stringify(userList)}`);
            sessionStorage.setItem(constants.SESSION_KEY_GETUSER_ME_RESPONSE, JSON.stringify(getUserMeResponse))
            setRoleSelected(roleSelected.length>0 ? roleSelected : null)
            
            console.log(`getUserDetails updata flow ${JSON.stringify(props.userId)}`);
            if (props.userId) {
                setisUpdateFlag(true);
                setisDisabledFlag(true);
                console.log(`getUserDetails updata flow ${JSON.stringify(props.userId)}`);
                const getUserbyIDResponse = await backendAPI.getUserByID(sessionStorage.getItem(constants.GC_TOKEN), props.userId)
                
                console.log(`getUserbyIDResponse Object is  ${JSON.stringify(getUserbyIDResponse)}`);                
                console.log(`getUserbyIDResponse Keys are ${JSON.stringify(Object.keys(getUserbyIDResponse.user))}`);
                console.log(`getUserbyIDResponse Role is ${JSON.stringify(getUserbyIDResponse.user.role)}`);
                console.log(`getUserbyIDResponse Disabled is ${JSON.stringify(getUserbyIDResponse.user.disabled)}`);
                console.log(`getUserbyIDResponse id is ${JSON.stringify(getUserbyIDResponse.user.id)}`);
                console.log(`getUserbyIDResponse email is ${JSON.stringify(getUserbyIDResponse.user.email)}`);
                console.log(`getUserbyIDResponse authorizedDivisions are ${JSON.stringify(getUserbyIDResponse.user.authorizedDivisions)}`);
                console.log(`getUserbyIDResponse authorizedQueues are ${JSON.stringify(getUserbyIDResponse.user.authorizedQueues)}`);
                console.log(`getUserbyIDResponse authorizedQueues length are ${JSON.stringify(getUserbyIDResponse.user.authorizedQueues.length)}`);
                console.log(`getUserbyIDResponse authorizedDivisions length are ${JSON.stringify(getUserbyIDResponse.user.authorizedDivisions.length)}`);

                if(getUserbyIDResponse.user.source && getUserbyIDResponse.user.source === constants.CONVERSATION_SOURCE_ENGAGE){
                    setsourceSelected({"value": constants.CONVERSATION_SOURCE_ENGAGE,"label": "Engage"});
                }else if(getUserbyIDResponse.user.source && getUserbyIDResponse.user.source === constants.CONVERSATION_SOURCE_GC){
                    setsourceSelected({"value": constants.CONVERSATION_SOURCE_GC,"label": "Genesys Cloud"});                    
                }else if(getUserbyIDResponse.user.source && getUserbyIDResponse.user.source === '*'){
                    setsourceSelected({"value": '*',"label": "All"});                    
                }else{
                    setsourceSelected({"value": constants.CONVERSATION_SOURCE_GC,"label": "Genesys Cloud"});
                }
                
                let selectedDivision = getUserMeResponse.user.divisions.filter((x => getUserbyIDResponse.user.authorizedDivisions.includes(x.value)));
                let selectedQueue = getUserMeResponse.user.queues.filter((x => getUserbyIDResponse.user.authorizedQueues.includes(x.value)));
                if(getUserbyIDResponse.user.authorizedDivisions.length == 1 && getUserbyIDResponse.user.authorizedDivisions[0]== "*"){
                    selectedDivision = getUserMeResponse.user.divisions ? getUserMeResponse.user.divisions.filter((x => x.value !== '*')) : [];
                    //console.log('Overriding all Division List');
                }

                if(getUserbyIDResponse.user.authorizedQueues.length == 1 && getUserbyIDResponse.user.authorizedQueues[0]== "*"){
                    selectedQueue = getUserMeResponse.user.queues ? getUserMeResponse.user.queues.filter((x => x.value !== '*')) : [];
                    //console.log('Overriding all Queue List');
                }
                console.log(`userSelected users are ${JSON.stringify(gcusersList)}`);
                console.log(`userSelected filter is ${JSON.stringify(gcusersList.filter((x => x.label.toLowerCase() == getUserbyIDResponse.user.email.toLowerCase())))}`);
                setUserSelected(getUserbyIDResponse.user.id?gcusersList.filter((x => x.label.toLowerCase() == getUserbyIDResponse.user.email.toLowerCase())) : []);
                console.log(`userSelected is ${JSON.stringify(userSelected)}`);
                setRoleSelected(getUserbyIDResponse.user.role);
                setDivisionsSelected(selectedDivision?selectedDivision.filter((x => x.value !== '*')): []);
                setQueuesSelected(selectedQueue.filter((x => x.value !== '*')));

                
                if(getUserbyIDResponse.user.authorizedPcDivisions){
                    let selectedPcDivision = getUserMeResponse.user.pcdivisions.filter((x => getUserbyIDResponse.user.authorizedPcDivisions.includes(x.value)));                
                    if(getUserbyIDResponse.user.authorizedPcDivisions.length == 1 && getUserbyIDResponse.user.authorizedPcDivisions[0]== "*"){
                        selectedPcDivision = getUserMeResponse.user.pcdivisions ? getUserMeResponse.user.pcdivisions.filter((x => x.value !== '*')) : [];                    
                    }
                    setpcDivisionsSelected(selectedPcDivision?selectedPcDivision.filter((x => x.value !== '*')): []);
                }
                
                if(getUserbyIDResponse.user.authorizedPcQueues){
                    let selectedPcQueue = getUserMeResponse.user.pcqueues.filter((x => getUserbyIDResponse.user.authorizedPcQueues.includes(x.value)));
                    if(getUserbyIDResponse.user.authorizedPcQueues.length == 1 && getUserbyIDResponse.user.authorizedPcQueues[0]== "*"){
                        selectedPcQueue = getUserMeResponse.user.pcqueues ? getUserMeResponse.user.pcqueues.filter((x => x.value !== '*')) : [];                    
                    }                
                    setpcQueuesSelected(selectedPcQueue?selectedPcQueue.filter((x => x.value !== '*')):[]);
                }

                if (getUserbyIDResponse.user.disabled){
                    setStateSelected(getUserbyIDResponse.user.disabled);
                }
                sessionStorage.setItem(constants.SESSION_KEY_GETUSER_BY_ID_RESPONSE, JSON.stringify(getUserbyIDResponse));
                if(getUserbyIDResponse.user.role.value === "admin"){
                    setDropDownsDisable(true);
                }else{
                    setDropDownsDisable(false);
                }
                
            }else{                
                setsourceSelected({"value": "*","label": "All"});
            }

        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }

        }

        getUserDetails()

    }, [])
    
    const filterQueue =  (DivisionsInp,queueToFilter)=> {
        let selectedDivison = DivisionsInp.map( e => e.value)
        let queuesFilter = []
        let filteredQueue = []
        selectedDivison.forEach (element => {
            queuesFilter = queueToFilter.filter(x => x.divisionId === element)
            filteredQueue = filteredQueue.concat(queuesFilter)
        })
        return (filteredQueue)

    }


    return (
  
            <div className = "form-elements">
            <WaitingModal
            isOpen={isWaitingModalOpen}
            header='Loading User Details'
            />
             <AlertModal
            isOpen={!!error}
            header={alertHeader}
            toggle={() => { setError(undefined) }}
            body={error}
            />
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>User Email <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <Select className = "single-select"
                       placeholder = "Select..."
                        options={userList}
                        value={userSelected}
                        onChange={e => { setUserSelected(e) }}
                        isDisabled={isDisabledFlag}
                    
                    />                
            </InputGroup>            
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Role <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                 <Select className = "single-select"
                       placeholder = "Select..."
                        options={rolesList}
                        value={roleSelected}
                        onChange={e => { setRoleSelected(e) 
                            if(e.value === "admin"){
                                setDropDownsDisable(true)
                                //setChannelsSelected(channelsList)                                
                                setDivisionsSelected(divisionsList)
                                setQueuesSelected(queuesList)

                                setpcDivisionsSelected(pcDivisionsList)
                                setpcQueuesSelected(pcQueuesList)
                            }
                            else{
                                setDropDownsDisable(false)
                                //setChannelsSelected([])
                                setDivisionsSelected([])
                                setQueuesSelected([])

                                setpcDivisionsSelected([])
                                setpcQueuesSelected([])
                            }
                            setsourceSelected({"value": "*","label": "All"});

                             }}
                    />
            </InputGroup>            
            <br />
            {roleSelected && roleSelected.value !='admin'?(            
            <div><InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Source</InputGroupText>
                </InputGroupAddon>
                <Select className="single-select"
                    options={[{"value": "*","label": "All"},{"value": constants.CONVERSATION_SOURCE_GC,"label": "Genesys Cloud"},{"value": constants.CONVERSATION_SOURCE_ENGAGE,"label": "Engage"}]}
                    value={sourceSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => {setsourceSelected(e)
                        setDivisionsSelected([])
                        setQueuesSelected([])

                        setpcDivisionsSelected([])
                        setpcQueuesSelected([])
                    }}
                    labelledBy={constants.CONVERSATION_SOURCE_GC}
                />
            </InputGroup>
            <br /></div>):''}
            
            {((roleSelected && roleSelected.value =='admin') || sourceSelected.value=='*' || sourceSelected.value==constants.CONVERSATION_SOURCE_GC)?(            
                <div>                                
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Divisions<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={divisionsList}
                    value={divisionsSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => {setDivisionsSelected(e)
                                    const selectedDivison = e;
                                    setDisplayQueueList(filterQueue(selectedDivison,queuesList))
                                    setQueuesSelected(filterQueue(selectedDivison,queuesSelected))
                                    }}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Queues<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={displayQueueList ? displayQueueList : 
                            (filterQueue(divisionsSelected,queuesList).length>0 ? filterQueue(divisionsSelected,queuesList) : queuesList)}
                    value={queuesSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => setQueuesSelected(e)}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />        
                </div>
                    ):''}
            {((roleSelected && roleSelected.value =='admin') || sourceSelected.value=='*' || sourceSelected.value==constants.CONVERSATION_SOURCE_ENGAGE)?(
            <div><InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Engage Divisions<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={pcDivisionsList}
                    value={pcDivisionsSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => {setpcDivisionsSelected(e)}}
                                    // const selectedDivison = e;
                                    // setpcDisplayQueueList(filterQueue(selectedpcDivison,pcQueuesList))
                                    // setpcQueuesSelected(filterQueue(selectedpcDivison,pcQueuesSelected))
                                    // }}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Engage Queues<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={pcQueuesList}
                    value={pcQueuesSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => setpcQueuesSelected(e)}
                    labelledBy="Select"
                />
            </InputGroup>
            <br /></div>):''}

            <Input addon type="checkbox" onChange={e => setStateSelected(e.target.checked)} checked={stateSelected} />
            <Label>&nbsp; Disable </Label>
            <br />
            <br />
                                    
            <Button color="primary" onClick={() => {
            
                if(sourceSelected && sourceSelected.value == constants.CONVERSATION_SOURCE_GC){
                    if (!userSelected || !roleSelected || !divisionsSelected || !queuesSelected
                        || (userSelected && userSelected.length === 0) 
                        || (roleSelected && roleSelected.length === 0) 
                        || (divisionsSelected && divisionsSelected.length === 0) 
                        || (queuesSelected && queuesSelected.length === 0)
                        ) {
                        setError(`Please enter all the required fields before submitting`)
                        setAlertHeader("Validation Error")
                        return
                    }
                }else if(sourceSelected && sourceSelected.value ==constants.CONVERSATION_SOURCE_ENGAGE){
                    if (!userSelected || !pcDivisionsSelected || !pcQueuesSelected
                        || (userSelected && userSelected.length === 0) 
                        || (roleSelected && roleSelected.length === 0) 
                        || (pcDivisionsSelected && pcDivisionsSelected.length === 0) 
                        || (pcQueuesSelected && pcQueuesSelected.length === 0)
                        ) {
                        setError(`Please enter all the required fields before submitting`)
                        setAlertHeader("Validation Error")
                        return
                    }
                }else if(sourceSelected && sourceSelected.value =='*'){
                    if (!userSelected || !roleSelected || !divisionsSelected || !queuesSelected
                        || (userSelected && userSelected.length === 0) 
                        || (roleSelected && roleSelected.length === 0) 
                        || (divisionsSelected && divisionsSelected.length === 0) 
                        || (queuesSelected && queuesSelected.length === 0)
                        || !pcDivisionsSelected || !pcQueuesSelected
                        || (roleSelected && roleSelected.length === 0) 
                        || (pcDivisionsSelected && pcDivisionsSelected.length === 0) 
                        || (pcQueuesSelected && pcQueuesSelected.length === 0)
                        ) {
                        setError(`Please enter all the required fields before submitting`)
                        setAlertHeader("Validation Error")
                        return
                    }
                }
                

                props.submit({
                    user:userSelected,
                    role: rolesList.length === roleSelected.length ? [''] : roleSelected,               
                    divisions: divisionsList.length === divisionsSelected.length ? [{
                        "value": "*",
                        "label": "All"
                    }] : divisionsSelected,
                    queues: queuesList.length === queuesSelected.length ? [{
                        "value": "*",
                        "label": "All"
                    }] : queuesSelected,
                    source: sourceSelected.value,
                    pcDivisions: pcDivisionsList.length === pcDivisionsSelected.length ? [{
                        "value": "*",
                        "label": "All"
                    }] : pcDivisionsSelected,
                    pcQueues: pcQueuesList.length === pcQueuesSelected.length ? [{
                        "value": "*",
                        "label": "All"
                    }] : pcQueuesSelected,
                    disabled: stateSelected

            })}}>{props.okButtonText ? props.okButtonText : 'OK'} </Button>
            &nbsp;&nbsp;
            <Button color="secondary" disabled={props.loading? true : false} onClick={() => props.cancel()}>{props.cancelButtonText ? props.cancelButtonText : 'Cancel'}</Button>
            <br />
            <br />
                    </div>
            
    )      
}

export default UserDetails