import config from '../config';
import constants from '../constants'

const fetchGCusers = async(env,token)=>{
  let pageNumber = 1;
  let allPagesProcessed = false;
  let userEmailList = [];
    do {
      let apiUrl = `https://api.${env}/api/v2/users?pageSize=500&pageNumber=${pageNumber}&sortOrder=ascending`  
      try {
        let usersResponse = await fetchWrapper(apiUrl, 
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token}`,
            },
          });
          if(usersResponse && usersResponse.entities && usersResponse.entities.length>0){
            console.log(`Current Page number is ${pageNumber}`);
            //console.log(`fetchGCusers api response is ${JSON.stringify(usersResponse)}`);
            userEmailList = userEmailList.concat(usersResponse.entities);
            pageNumber = pageNumber +1;
            allPagesProcessed = false;
          }else{
            console.log(`No more users to be returned`);
            allPagesProcessed = true;
          }
          
          
      } catch (error) {
        console.log(`Error is in fetchGCusers `)
        console.log(error);
      }
      
      //allPagesProcessed = true;
    } while (allPagesProcessed == false);
    userEmailList = userEmailList.map(x => ({'value':x.id,'label': x.email}));
    console.log(userEmailList);
  return userEmailList;
}


const fetchPagedWrapper = async (env, url, init, dataSet) => {
  const initBodyParams = init.body && JSON.parse(init.body);
  if (initBodyParams) {
    initBodyParams.pageSize = "25";
    initBodyParams.pageNumber = 1;
    init.body = JSON.stringify(initBodyParams);
  }

  return fetchWrapper(`https://api.${env}${url}`, init).then((fetchData) => {
    if (!dataSet) {
      dataSet = [];
    }
    dataSet.push(...fetchData.results);

    if (fetchData.nextPage) {
      // When there is a next data page, change API call to GET method and remove (POST) body parameters
      init.body = undefined;
      init.method = "GET";
      return fetchPagedWrapper(env, fetchData.nextPage, init, dataSet);
    }
    return dataSet;
  });
};
const fetchWrapper = async (url, init) => {
  const response = await fetch(url, init);
  let correlationId;
  for (let pair of response.headers.entries()) {
    if (pair[0] === "inin-correlation-id") {
      correlationId = pair[1];
      break;
    }
  }
  const json = await response.json();
  return response.ok
    ? { ...json, correlationId }
    : Promise.reject({ ...json, correlationId });
};

const getCurrentUserDetails = (env, token) => {
  console.log(`getme .env:${env}, token:${token}`);
  return fetchWrapper(`https://api.${env}/api/v2/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  });
};
const getRoles = (env, token, id) => {
  console.log(`getRoles token:${token}, id:${id}`);
  return fetchWrapper(`https://api.${env}/api/v2/users/${id}/roles`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  });
};


const isLoggedIn = () => {
    console.log('getting token', sessionStorage.getItem(constants.GC_TOKEN))
    return sessionStorage.getItem(constants.GC_TOKEN) !== null
}

const getOrganization = (environment, token) => {
    console.log(`getOrganization.env:${environment}, token:${token}`)
    return fetchWrapper(`https://api.${environment}/api/v2/organizations/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
        }
    })
}

export const pureCloudService = {
  isLoggedIn,
  getOrganization,
  fetchWrapper,
  getCurrentUserDetails,
  getRoles,
  fetchGCusers
};