import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Player } from 'video-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import "video-react/dist/video-react.css";
import './PCRecordingPlaybackModal.css'

const PCRecordingPlaybackModal = (props) => {
    const { isOpen, toggle, recording } = props

    const getModalSize = () => {
        return 'lg';
    }

    const modalBody = () => {
        return <audio
            autoPlay
            title={recording.recordingid}
            id={`audio_${recording.recordingid}`}
            controls            
            src={recording.url}
            className="custom-audio"
        >
            <p>
                Your browser doesn't support HTML5 audio. Here is a
                <a href={recording.url}>link to download the audio</a> instead.
            </p>
        </audio>
    }
    return (
        <div>
            {recording &&
                (<Modal size={getModalSize()} isOpen={isOpen}>
                    <ModalHeader>Recording Playback - {recording && recording.recordingid}
                        <button onClick={() => { navigator.clipboard.writeText(recording.recordingid) }}><FontAwesomeIcon icon={faCopy} /></button>
                    </ModalHeader>
                    <ModalBody>
                        {modalBody()}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => { toggle() }}>Close</Button>
                    </ModalFooter>
                </Modal>)
            }
        </div>
    )
}

export default PCRecordingPlaybackModal