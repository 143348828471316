import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import backend from '../../services/backend'
import ReactTable from "react-table-6";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faFileVideo, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

import PCRecordingPlaybackModal from '../Modals/PCRecordingPlaybackModal/PCRecordingPlaybackModal'
import PCEmailTranscriptView from '../emailTranscriptView/PCEmailTranscriptView'
import PCChatTranscriptView from '../chatTranscriptView/PCChatTranscriptView'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import AlertModal from '../Modals/alertModal/AlertModal'
import constants from '../../constants'
import moment from 'moment'

import './PCConversationDetails.css'
import "react-table-6/react-table.css";

const PCConversationDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [recordings, setRecordings] = useState([])
    const [conversation, setConversation] = useState()
    const [isRecordingPlaybackModalOpen, setIsRecordingPlaybackModalOpen] = useState(false)
    const [recordingPlayback, setRecordingPlayback] = useState()
    const [role, setRole] = useState()
    const [source, setSource] = useState();
    const history = useHistory()
    const { id } = useParams()

    useEffect(() => { // init load
        setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
        setSource(sessionStorage.getItem(constants.R2S_USER_SOURCE));
        console.log('Conversation Details role is'+role);
        const fetchConversation = async () => {
            try {
                setIsWaitingModalOpen(true)
                let response = await backend.getPCConversation(sessionStorage.getItem(constants.GC_TOKEN), id)
                console.log('getConversation.response:', response);
                
                response.mediatypename = 'call';

                setConversation(response);
                setRecordings(response.recordings);
            } catch (error) {
                console.log('fetchConversation error in the recording generation:,', error)
                setError(`An error occured while fetching the conversation:${JSON.stringify(error.message)}`)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        fetchConversation()
    }, [id])

    const handleRecordingClick = (recording) => {
        console.log('handleRecordingClick::::::', recording)
        setRecordingPlayback(recording)
        toggleRecordingPlaybackModal()
    }

    const toggleRecordingPlaybackModal = () => setIsRecordingPlaybackModalOpen(!isRecordingPlaybackModalOpen)

    const conditionallyRenderConversation = () => {
        console.log(`Result Conversation is ${JSON.stringify(conversation)}`);
        if (!conversation) return
        switch (conversation.mediatypename.toLowerCase()) {
            case 'chat':
                console.log(`Going to Render MediaType Chat with ${JSON.stringify(recordings)}`);
                return <Fragment>
                    {recordings &&
                        <PCChatTranscriptView
                            id={conversation.conversation_id}
                            recordings={recordings}
                        />}
                </Fragment>

            case 'email':
            case 'sms':
                console.log(`Going to Render MediaType Email`);
                return <Fragment>
                    {recordings &&
                        <PCEmailTranscriptView
                            id={conversation.conversation_id}
                            recordings={recordings}
                        />}
                </Fragment>
            default: // voice
            console.log(`Going to render MediaType Call`);
            console.log(`Going to render ${JSON.stringify(recordings)}`);
                return <div className="px-2">
                    <Row>
                        <Col>
                            <span className="details-header">Conversation Recordings</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                              <ReactTable
                                showPageSizeOptions={true}
                                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                                defaultPageSize={10}
                                minRows={0}
                                className="-highlight -striped table-container"
                                data={recordings}
                                columns={[
                                    {
                                        Header: 'Recording Start Time',
                                        accessor: 'recording_start_ts',
                                        headerClassName: 'headerColumn',
                                        Cell: row => {
                                            return (
                                                <div className="overflow div-center">                                                    
                                                    {moment(row.original.recording_start_ts).format("MMM Do YYYY, h:mm:ss a Z")}
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        Header: 'Recording End Time',
                                        accessor: 'recording_end_ts',
                                        headerClassName: 'headerColumn',
                                        Cell: row => {
                                            return (
                                                <div className="overflow div-center">
                                                    {moment(row.original.recording_end_ts).format("MMM Do YYYY, h:mm:ss a Z")}
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        Header: 'Agent',
                                        accessor: 'agentname',
                                        headerClassName: 'headerColumn',
                                        Cell: row => {
                                            return (
                                                <div className="overflow div-center">
                                                    {row.original.agent_name}
                                                </div>
                                            )
                                        }
                                    }
                                , 
                                {
                                    Header: 'Play',
                                    headerClassName: 'headerColumn',
                                    Cell: row => {
                                            return (
                                                <div className="div-center">
                                                    <button onClick={() => { handleRecordingClick(row.original) }}>
                                                        <FontAwesomeIcon icon={faPlay} />
                                                    </button>
                                                </div>
                                            );                                            
                                    }                                    
                                }
                                ]}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            cursor: 'pointer'
                                        }
                                    }
                                }}
                                getTrProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                getTrGroupProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                // defaultFilterMethod={(filter, row) => {
                                //     const id = filter.pivotId || filter.id;
                                //     return (row[id] !== undefined ?
                                //         String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                                //         : true
                                //     );
                                // }}
                              
                            />  
                        </Col>
                    </Row>
                </div>
        }
    }

    return (
        <div id="component-wrap">
            <Header role={role} source={source}/>
            <div id="main">
                <WaitingModal
                    isOpen={isWaitingModalOpen}
                    header='Loading conversation details'
                />
                <AlertModal
                    isOpen={!!error}
                    header='Error'
                    toggle={() => { setError(undefined) }}
                    body={error}
                />

                <PCRecordingPlaybackModal
                    isOpen={isRecordingPlaybackModalOpen}
                    toggle={() => { toggleRecordingPlaybackModal() }}
                    recording={recordingPlayback}
                />

                <div className="px-2">
                    <Row>
                        <Col>

                            <button onClick={() => { history.push('/engage') }}>
                                <FontAwesomeIcon className="fa-2x" icon={faChevronCircleLeft} />
                            </button>
                            <span className="conversations-details-header-text">Conversation Details - {conversation && conversation.conversation_id}</span>

                            <hr />
                        </Col>
                    </Row>
                    
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Start Date</span><span className="details-value">{conversation && moment(conversation.conversation_start_ts).format("MMM Do YYYY, h:mm:ss a Z")}</span>
                        </Col>
                        <Col>
                            <span className="details-header">End Date</span><span className="details-value">{conversation && moment(conversation.conversation_end_ts).format("MMM Do YYYY, h:mm:ss a Z")}</span>
                        </Col>
                    </Row>                    
                    <Row xs="2">
                        <Col>
                            <span className="details-header">ANI</span><span className="details-value">{conversation && conversation.ani}</span>
                        </Col> 
                        <Col>
                            <span className="details-header">DNIS</span><span className="details-value">{conversation && conversation.dnis}</span>
                        </Col>                        
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Queue</span><span className="details-value">{conversation && conversation.queue_name}</span>
                        </Col> 
                        <Col>
                            <span className="details-header">Division</span><span className="details-value">{conversation && conversation.division}</span>
                        </Col>                        
                    </Row>
                    <Row xs="2">                        
                        <Col>
                            <span className="details-header">Conversation Direction</span><span className="details-value">{conversation && conversation.direction}</span>
                        </Col>                       
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                </div>
                 {conditionallyRenderConversation()}
            </div>
            <Footer />
        </div>
    )
}

export default PCConversationDetails